<template>
    <div>
        <app-top-bar title="My Profile" left-icon="mdi-arrow-left" @leftIconEvent="go"></app-top-bar>
        <div class="grey lighten-4">
            <v-card two-lines class="rounded-0 elevation-0 mb-6">
                <v-list class="py-0">
                    <v-subheader class="text-subtitle-1">Basic Information</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>First Name</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.first_name"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Last Name</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.last_name"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Email</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.email"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Gender</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.gender"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Date of Birth</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.dob?object.profile.dob:'N.A.'"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Mobile No.</v-list-item-title>
                            <v-list-item-subtitle v-text="object.profile.mobile_number?object.profile.mobile_number:'N.A.'"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list>
            </v-card>
            <v-card two-lines class="rounded-0 elevation-0 my-3">
                <v-list class="py-0">
                    <v-subheader class="text-subtitle-1">Address Information</v-subheader>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Block No.</v-list-item-title>
                            <v-list-item-subtitle v-text="object.address.block_number"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Street Name</v-list-item-title>
                            <v-list-item-subtitle v-text="object.address.street_name"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Unit Number</v-list-item-title>
                            <v-list-item-subtitle v-text="object.address.unit_number"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Building Name/Residence Name</v-list-item-title>
                            <v-list-item-subtitle v-text="object.address.building_name"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>Postal Code</v-list-item-title>
                            <v-list-item-subtitle v-text="object.address.postal_code"></v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </v-list>
            </v-card>
        </div>
    </div>
</template>


<script>

import AppTopBar from '../../layout/AppTopBar.vue'

export default {
    name: 'MyProfile',
    components: {
        appTopBar: AppTopBar,
    },
    data() {
        return {
            loading: false,
            object: {
                profile:{
                    first_name: '',
                    last_name: '',
                    email: '',
                    gender: '',
                    dob: '',
                    mobile_number:'',
                },
                address: {
                    block_number: '',
                    street_name: '',
                    unit_number: '',
                    building_name: '',
                    postal_code: '',
                }
            },
        }
    },
    mounted: function() {
        this.get()
    },
    methods: {
        go: function() {
            this.$router.push({ path: '/me' })
        },
        get: function() {
            this.$store.dispatch('mobile/account/getProfile').then((response) => {
                this.object = response.data.object
                this.loading = false
            }).catch(() => {
                this.loading = false
            })
        },
        getStyle: function() {
            const minHeight = (window.innerHeight - 56).toString() + "px";
            return {"min-height": minHeight}
        },
    }
}

</script>

<style scoped>

/deep/ .v-subheader {
    height: 40px !important;
}

</style>